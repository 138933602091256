import { IUser } from 'app/modules/admin/common/settings/users/users.types';
import {
    IDrugstore,
    IExpepharmaResponse,
    IPaginationRessource,
} from '../global.types';
import { IProduct } from '../product/product.types';
import { IBasket } from 'app/layout/common/basket/basket.types';
import { IPharmaOrderDetailsSimple } from 'app/modules/admin/pharma/pharma-orders/pharma-orders.type';
import { ICategorizations, ICategorizationsPrice } from '../user/user.types';

/**
 * Interface for paginated HTTP response containing array of pharmacy offers.
 * Extends IPagination interface.
 * data property contains array of IPharmaOfferFull pharmacy offer objects.
 */
export interface IHttpPharmaOffer extends IPaginationRessource {
    data: IPharmaOfferFull[];
}

/**
 * Interface for a Pharma Offer object with full details.
 * Extends IPharmaOffer and contains additional properties like:
 * - product: The product associated with the offer.
 * - user: The user who created the offer.
 * - parent_id: The parent offer ID if this is a child offer.
 * - id: The unique ID of the offer.
 */
export interface IPharmaOfferFull extends IPharmaOffer {
    product: IProduct;
    user: IUser;
    parent_id: number;
    id: number;
    baskets?: IBasket[];
    children?: IPharmaOfferFull[];
    orders_details?: IPharmaOrderDetailsSimple[];
    free_products?: IFreeProduct[];
    already_proposal?: boolean;
    limitations?: number;
    is_limited?: boolean;
}

export interface IFreeProduct {
    id: number;
    offer_id: number;
    product_id: number;
    position: number;
    minimum_qty: number;
    offer_qty: number;
    unit_price: string;
    created_at: Date;
    updated_at: Date;
    product: IProductSimple;
}

export interface IProductSimple {
    id: number;
    label: string;
    cip13: string;
}

/**
 * Enumeration of offer limitation types.
 * - PER_DAY: Offer is limited per day.
 * - PER_CALENDAR_WEEK: Offer is limited per calendar week.
 * - PER_ROLLING_WEEK: Offer is limited per rolling week.
 * - PER_CALENDAR_MONTH: Offer is limited per calendar month.
 * - PER_ROLLING_MONTH: Offer is limited per rolling month.
 */
export enum EnumLimitationOffer {
    PER_DAY = 'per_day',
    PER_ROLLING_WEEK = 'per_rolling_week',
    PER_ROLLING_DECA = 'per_rolling_deca',
    PER_ROLLING_QUIZAINE = 'per_rolling_quinzaine',
    PER_ROLLING_MONTH = 'per_rolling_month',
}

export enum EnumOwnerActionOffer {
    CLOSING = 'closing',
    PENDING = 'pending',
    STOCK = 'stock',
    DELAY = 'delay',
    OPENING = 'opening',
    CLONING = 'cloning',
    PRICE = 'price',
    VAT = 'vat',
    WEIGHT = 'weight',
    WIDTH = 'width',
    HEIGHT = 'height',
    DEPTH = 'depth',
    TRANSPORT = 'transport',
    MIN_QTY = 'min_qty',
    MAX_QTY = 'max_qty',
}

/**
 * Enumeration of offer shelf life types.
 *
 * - DAY: Offer available for a set number of days.
 * - TIME: Offer available until a specific timestamp.
 */
export enum EnumOfferShelfType {
    DAY = 'day',
    TIME = 'timestamp',
}

/**
 * Enumeration of offer types.
 * - FIX: Fixed price offer.
 * - FLASH: Flash sale offer.
 * - SHELFLIFE: Offer available for a limited shelf life.
 * - THRESHOLD: Offer available when order value exceeds threshold.
 * - QUOTATION: Offer requires a quotation.
 */
export enum EnumOfferType {
    FIX = 'fix',
    FLASH = 'flash',
    SHELFLIFE = 'shelflife',
    THRESHOLD = 'threshold',
    QUOTATION = 'quotation',
}

/**
 * Enumeration of offer stock types.
 *
 * - ENTRANCE: Stock added when offer created.
 * - EXIT: Stock removed when offer expires.
 * - INITIAL: Initial stock level.
 * - ADJUSTMENT: Stock adjusted manually.
 * - RETURN: Stock returned after sale.
 */
export enum EnumOfferStockType {
    ENTRANCE = 'entrance',
    EXIT = 'exit',
    INITIAL = 'initial',
    ADJUSTMENT = 'adjustment',
    RETURN = 'return',
}

/**
 * Enumeration of possible offer status values.
 * - CLOSED: The offer is closed and no longer available.
 * - PROGRESS: The offer is still open and in progress.
 * - CANCELED: The offer was canceled before completion.
 * - PENDING: The offer is pending approval or some other action before opening.
 */
export enum EnumOfferStatus {
    CLOSED = 'closed',
    PROGRESS = 'progress',
    CANCELED = 'canceled',
    PENDING = 'pending',
}

/**
 * Interface for a pharmaceutical offer object.
 * Defines the structure of an offer with various properties like product ID, type,
 * quantities, pricing, shelf life etc.
 */
export interface IPharmaOffer {
    id?: number;
    product_id: number;
    hash?: string;
    type: EnumOfferType;
    total_qty: number;
    min_qty: number;
    max_qty: number;
    request_qty: number;
    shelflife_type: EnumOfferShelfType;
    shelflife: string;
    is_free_transport: boolean;
    weight: number;
    width: number;
    depth: number;
    height: number;
    vat: number;
    is_end_offer: boolean;
    end_offer: string;
    max_date_response: string;
    wished_delivery: string;
    step: number;
    init_price: number;
    flash_price: number;
    is_range_price: boolean;
    low_range_price: number;
    high_range_price: number;
    is_threshold_active: boolean;
    user: IUser;
    thresholds: IPharmaOfferThreshold[];
    stocks?: IPharmaOfferStock[];
    status: EnumOfferStatus;
    is_favorite?: boolean;
    picture: string;
    categorizations_prices?: ICategorizationsPrice[];
}

/**
 * Interface defining the structure of a threshold object
 * within a pharmaceutical offer.
 * Specifies the minimum quantity, unit price, and position
 * for each tiered pricing threshold.
 */
export interface IPharmaOfferThreshold {
    minimum_qty: number;
    unit_price: number;
    position: number;
}

/**
 * Interface defining the structure of a pharmaceutical offer stock object.
 * Contains properties like id, offer id, user id, type, and quantity.
 */
export interface IPharmaOfferStock {
    id: number;
    offer_id: number;
    user_id: number;
    type: EnumOfferStockType;
    qty: number;
}

/**
 * Interface defining the structure of a realtime stock object
 * for a user's pharmaceutical offers.
 * Contains the user id and an array of offer ids.
 */
export interface IPharmaOfferStockRealtime {
    user_id: number;
    offers: number[];
}

/**
 * Interface defining the structure of a realtime stock object
 * for a user's pharmaceutical offers.
 * Contains the offer id and stocks array.
 */
export interface IPharmaOfferStockRealtimeUpdated {
    offer_id: number;
    stocks: IPharmaOfferStock[];
}

/**
 * Interface defining the structure of the HTTP response
 * for getting realtime stock updates for a user's
 * pharmaceutical offers.
 * Contains a success boolean and an array of stock
 * objects with offer ID and updated stocks.
 */
export interface IHttpPharmaOfferStockRealtimeUpdated {
    success: boolean;
    data: IPharmaOfferStockRealtimeUpdated[];
}

export interface IHttpPharmaOfferUniq {
    success: boolean;
    message: string;
    data: IPharmaOfferFull;
}

export interface IPharmaBasicQuotation {
    id: number;
    max_date_response: string;
    wished_delivery: string;
    high_range_price: boolean;
    low_range_price: number;
    shelflife_type: EnumOfferShelfType;
    shelflife: string;
}

export interface IHttpPharmaOfferResponse extends IExpepharmaResponse {
    data: IPharmaOfferFull[];
}

export interface IHttpCategorizationResponse extends IExpepharmaResponse {
    data: ICategorizations;
}

export interface IHttpSearchCompagniesCategorizationResponse
    extends IExpepharmaResponse {
    data: IDrugstore[];
}

export interface IUploadLinkedCompaniesResponse {
    type: 'progress' | 'result';
    progress?: number;
    result?: IHttpSearchCompagniesCategorizationResponse;
}
