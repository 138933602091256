import { Component, Input } from '@angular/core';
import { HistoryService } from 'app/core/history/history.service';
import { AlertService } from 'app/core/alerts/alert.service';
import {
    Observable,
    Subject,
    combineLatest,
    filter,
    map,
    mergeMap,
    takeUntil,
} from 'rxjs';
import { IAlert } from 'app/core/alerts/alert.type';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { SeoService } from './core/seo/seo.service';
// import { SwPush } from '@angular/service-worker';
import { PusherService } from './core/pusher/pusher.service';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { LocalizedDatePipe, subscribeToChannel } from './core/global.helpers';
import { FilterService } from './core/services/filters/filters.service';
import { UserService } from './core/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { PhoneConfirmationComponent } from './modules/admin/common/components/account/phone-confirmation/phone-confirmation.component';
import { ValidationAccountComponent } from './modules/admin/common/components/account/validation-account/validation-account.component';
import { TTypeAccount, User } from './core/user/user.types';
import { ChoiceTypeValidationComponent } from './modules/admin/common/components/account/choice-type-validation/choice-type-validation.component';
import { VersionService } from './core/services/version/version.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [LocalizedDatePipe],
    animations: [
        trigger('modalState', [
            state('visible', style({ opacity: 1, transform: 'scale(1)' })),
            state('hidden', style({ opacity: 0, transform: 'scale(0.5)' })),
            transition('hidden => visible', [
                animate(
                    '300ms ease-in',
                    style({ opacity: 1, transform: 'scale(1)' })
                ),
                animate('150ms ease-in', style({ transform: 'scale(1.1)' })),
                animate('150ms ease-in', style({ transform: 'scale(1)' })),
            ]),
            transition('visible => hidden', animate('300ms ease-out')),
        ]),
    ],
})
export class AppComponent {
    alert$: Observable<IAlert>;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    @Input() showModal: boolean = false;
    version: string = 'v0.0.0';
    updatedAt: string = new Date().toISOString();
    /**
     * Constructor
     */
    constructor(
        private _historyService: HistoryService,
        private _alertService: AlertService,
        private _seoService: SeoService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        // private swPush: SwPush,
        private _pusherService: PusherService,
        private _filterService: FilterService,
        private localizedDate: LocalizedDatePipe,
        private _userService: UserService,
        private _matDialog: MatDialog,
        private _versionService: VersionService
    ) {
        // this.swPush.messages.subscribe();
        this._filterService
            .init()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();
    }

    ngOnInit(): void {
        this._historyService.init();
        this.alert$ = this._alertService.alert$;
        this._alertService.alert$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe();

        this._versionService
            .checkVersion()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (version: string) => {
                    const localVersion = localStorage.getItem('appVersion');
                    this._versionService;
                    if (!localVersion || localVersion !== version) {
                        localStorage.setItem('appVersion', version);
                        this.version = version;
                        this.removeCache().subscribe({
                            next: () => {
                                window.location.reload();
                            },
                            error: (error) => {
                                console.error(
                                    'Error while removing cache:',
                                    error
                                );
                                window.location.reload();
                            },
                        });
                    }
                },
                error: (error) => {
                    console.error(
                        'Erreur lors de la vérification de la version:',
                        error
                    );
                },
            });

        this.router.events
            .pipe(
                takeUntil(this._unsubscribeAll),
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                if (event['title']) {
                    this._seoService.updateTitle(event['title']);
                }
                if (event['ogUrl']) {
                    this._seoService.updateOgUrl(event['ogUrl']);
                }
                if (event['description'] && event['title']) {
                    this._seoService.updateDescription(
                        event['title'] + event['description']
                    );
                }
            });

        this._pusherService.channels$
            .pipe(
                subscribeToChannel('publicChannel', 'update', (data) => {
                    const localVersion = localStorage.getItem('appVersion');
                    this._versionService
                        .checkVersion()
                        .subscribe((version: string) => {
                            if (!localVersion || localVersion !== version) {
                                localStorage.setItem('appVersion', version);
                                this.version = version;
                                this.showModal = true;
                                // window.location.reload(true);
                            }
                        });
                })
            )
            .subscribe();

        combineLatest([
            this._userService.user$,
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd)
            ),
        ])
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(([user, event]) => {
                const navigationEndEvent = event as NavigationEnd;
                if (user && this.isProtectedRoute(navigationEndEvent.url)) {
                    if (user.status === 'pending') {
                        if (
                            user.phone_activation === false &&
                            user.type === 'pharma'
                        ) {
                            this.openChoicePharma(user);
                            // this.openValidationPhone(user);
                        } else {
                            this.openValidationAccount(user);
                        }
                    }
                }
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    /**
     * Refreshes the current page by reloading the window.
     */
    refreshPage() {
        this.removeCache().subscribe({
            next: () => {
                window.location.reload();
            },
            error: (error) => {
                console.error('Error while removing cache:', error);
                window.location.reload();
            },
        });
    }

    /**
     * Transforms a date string into a localized, full date format.
     *
     * @param date - The date string to be transformed.
     * @returns The transformed date string in a localized, full date format.
     */
    transformDate(date: string): string {
        return this.localizedDate.transform(date, 'full');
    }

    /**
     * Opens a validation account modal dialog for the given user.
     *
     * @param user - The user object containing the necessary information to display the validation account modal.
     * @returns Void
     */
    openValidationAccount(user: User) {
        this._matDialog.open(ValidationAccountComponent, {
            data: {
                type: user.type,
                suscription: user.suscription,
                title: this.titleModal(user.type),
            },
            maxWidth: 500,
            width: '500px',
            disableClose: true,
        });
    }

    /**
     * Opens a modal dialog to confirm the user's phone number.
     *
     * @param user - The user object containing the user's information.
     * @returns Void
     */
    openValidationPhone(user: User) {
        const modal = this._matDialog.open(PhoneConfirmationComponent, {
            data: {
                type: user.type,
                suscription: user.suscription,
                title: this.titleModal(user.type),
            },
            maxWidth: 500,
            width: '500px',
            disableClose: true,
        });

        modal.afterClosed().subscribe(() => {
            this._userService
                .updatePhoneActivation(true)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe();
        });
    }

    openChoicePharma(user: User) {
        const modal = this._matDialog.open(ChoiceTypeValidationComponent, {
            data: {
                type: user.type,
                suscription: user.suscription,
                title: this.titleModal(user.type),
            },
            maxWidth: 500,
            width: '500px',
            disableClose: true,
        });

        modal.afterClosed().subscribe(() => {
            this._userService
                .updatePhoneActivation(true)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe();
        });
    }

    removeCache(): Observable<void> {
        return new Observable((observer) => {
            if ('caches' in window) {
                caches.keys().then((cacheNames) => {
                    Promise.all(
                        cacheNames.map((cacheName) => {
                            console.log(`Deleting cache: ${cacheName}`);
                            return caches.delete(cacheName);
                        })
                    )
                        .then(() => {
                            console.log('All caches deleted successfully.');
                            observer.next();
                            observer.complete();
                        })
                        .catch((error) => {
                            console.error('Error deleting caches:', error);
                            observer.error(error);
                        });
                });
            } else {
                console.warn('Cache API not supported in this browser.');
                observer.error('Cache API not supported in this browser.');
            }
        });
    }

    /**
     * Determines the title of the modal based on the user's account type.
     * @param type - The type of the user's account.
     * @returns The title of the modal.
     */
    titleModal(type: TTypeAccount): string {
        switch (type) {
            case 'wholesaler_pharma':
                return 'selected-type-wholesaler-pharma';
            case 'wholesaler_nopharma':
                return 'selected-type-wholesaler-nopharma';
            default:
                return 'selected-type-pharma';
        }
    }

    /**
     * Checks if the provided URL contains the 'secure' string, indicating a protected route.
     * @param url - The URL to check.
     * @returns `true` if the URL contains 'secure', `false` otherwise.
     */
    private isProtectedRoute(url: string): boolean {
        return url.includes('secure');
    }

    openCommentZoho() {
        const width = 470;
        const height = 600;

        const left = window.screenX + window.outerWidth / 2 - width / 2;
        const top = window.screenY + window.outerHeight / 2 - height / 2;
        window.open(
            'https://desk.zoho.eu/support/fbw?formType=AdvancedWebForm&fbwId=edbsn7a4835254fb60a26db2516c80d08e075d592c78e8b80b3ae6f5e4fdd6abce440&xnQsjsdp=edbsnebedb33abbf3b6f63bd0d59efbca6e9e&mode=showNewWidget&displayType=iframe',
            '_blank',
            `width=${width},height=${height},left=${left},top=${top}`
        );
    }
}
