import {
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    OnDestroy,
    Pipe,
    PipeTransform,
    Renderer2,
} from '@angular/core';
import {
    ValidatorFn,
    FormArray,
    AbstractControl,
    ValidationErrors,
    UntypedFormGroup,
    FormGroup,
} from '@angular/forms';
import { IFee, TStatus } from './global.types';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { TSuscription, TTypeAccount, User } from './user/user.types';
import { EnmumMarketplaceType } from './quotation/quotation.types';
import {
    IPharmaOfferStock,
    EnumOfferStockType,
    IPharmaOfferFull,
} from './pharma-offer/pharma-offer.types';
import {
    EnumStepClient,
    EnumTypeClient,
    IMandatoryStep,
} from 'app/modules/admin/wholesale/orders/orders.types';
import {
    Observable,
    Subject,
    Subscription,
    catchError,
    concatMap,
    delay,
    filter,
    first,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    throwError,
} from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import {
    EnumBasketType,
    IBasket,
    IBasketGroupUser,
    IBasketSplitVat,
    ICarton,
    ICartonProduct,
    ICartonSize,
} from 'app/layout/common/basket/basket.types';
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';

export const darkBackground = [
    '#2E86C1', // Bleu océan
    '#2ECC71', // Vert émeraude
    '#3498DB', // Bleu clair
    '#8E44AD', // Violet sombre
    '#16A085', // Vert bouteille
    '#27AE60', // Vert forêt
    '#D35400', // Orange brûlé
    '#2980B9', // Bleu outremer
    '#7F8C8D', // Gris ardoise
    '#C0392B', // Rouge rubis
    '#F39C12', // Orange sombre
    '#D35400', // Tangerine
    '#9B59B6', // Violet moyen
    '#E74C3C', // Rouge clair
    '#2C3E50', // Bleu nuit
    '#34495E', // Bleu gris
    '#7D3C98', // Prune
    '#1ABC9C', // Turquoise
    '#212F3D', // Bleu charbon
    '#5D6D7E', // Gris acier
];

/**
 * GoodChipPipe est un pipe Angular qui permet de transformer une valeur de type
 * TStatus en classe CSS pour l'affichage d'un badge.
 *
 * @param value La valeur de type TStatus à transformer
 * @returns La classe CSS correspondante pour l'affichage du badge
 */
@Pipe({ name: 'goodChip' })
export class GoodChipPipe implements PipeTransform {
    transform(value: TStatus): string {
        switch (value) {
            case 'pending':
                return 'pending-button';
            case 'active':
                return 'active-button';
            case 'inactive':
                return 'inactive-button';
            case 'blocked':
                return 'blocked-button';
            default:
                return 'incoming-button';
        }
    }
}

/**
 * GoodChipSusPipe est un pipe Angular qui permet de transformer une valeur de type
 * TSuscription en classe CSS pour l'affichage d'un badge.
 *
 * @param value La valeur de type TSuscription à transformer
 * @returns La classe CSS correspondante pour l'affichage du badge
 */
@Pipe({ name: 'goodChipSus' })
export class GoodChipSusPipe implements PipeTransform {
    transform(value: TSuscription): string {
        switch (value) {
            case 'base':
                return 'base-button';
            case 'premium':
                return 'premium-button';
            case 'pro':
                return 'pro-button';
            default:
                return 'incoming-button';
        }
    }
}

/**
 * GoodChipForTypePipe est un pipe Angular qui permet de transformer une valeur d'enum EnmumMarketplaceType en classe CSS pour l'affichage d'un badge.
 *
 * @param value La valeur d'enum EnmumMarketplaceType à transformer
 * @returns La classe CSS correspondante pour l'affichage du badge
 */
@Pipe({ name: 'goodChipForType' })
export class GoodChipForTypePipe implements PipeTransform {
    transform(value: EnmumMarketplaceType): string {
        switch (value) {
            case EnmumMarketplaceType.FIXED:
                return 'chip-action fixed-type-button';
            case EnmumMarketplaceType.PROPOSAL:
                return 'chip-action proposal-type-button';
            case EnmumMarketplaceType.QUOTATION:
                return 'chip-action quotation-type-button';
            default:
                return 'chip-action all-type-button';
        }
    }
}

/**
 * LocalizedDatePipe est un pipe Angular qui permet de formater une date dans la langue active de l'application.
 *
 * Il utilise le service TranslocoService pour récupérer la langue active,
 * et le pipe DatePipe d'Angular pour formater la date dans cette langue.
 *
 * @param value La valeur de date à formater
 * @param pattern Le format de date souhaité (par défaut 'mediumDate')
 * @returns La date formatée dans la langue active de l'application
 */
@Pipe({
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private _translocoService: TranslocoService) {}

    transform(value: any, pattern: string = 'mediumDate'): any {
        const datePipe: DatePipe = new DatePipe(
            this._translocoService.getActiveLang().toLowerCase()
        );
        return datePipe.transform(value, pattern);
    }
}

/**
 * EnumKeyPipe est un pipe Expepharma qui permet de récupérer la clé d'une valeur d'enum.
 *
 * @param value La valeur de l'enum dont on veut récupérer la clé
 * @param enumObject L'objet contenant les paires clé/valeur de l'enum
 * @returns La clé correspondant à la valeur passée en paramètre, ou null si aucune correspondance n'est trouvée
 */
@Pipe({
    name: 'enumKey',
})
export class EnumKeyPipe implements PipeTransform {
    transform(value: any, enumObject: any): string | null {
        for (const enumMember in enumObject) {
            if (enumObject[enumMember] === value) {
                return enumMember;
            }
        }
        return null;
    }
}

/**
 * Tableau définissant la traduction des types de médicaments.
 * Chaque entrée contient le type sous forme de chaîne et la clé de traduction associée.
 */
export const typeOfMedicamentTranslation = [
    { type: 'is_dopant', key: 'title-is_dopant' },
    { type: 'is_generic', key: 'title-is_generic' },
    { type: 'is_exceptional', key: 'title-is_exceptional' },
    { type: 'is_t2a', key: 'title-is_t2a' },
    {
        type: 'is_enhanced_surveillance',
        key: 'title-is_enhanced_surveillance',
    },
    { type: 'is_biosimilaire', key: 'title-is_biosimilaire' },
    { type: 'is_homeo', key: 'title-is_homeo' },
    { type: 'is_tfr', key: 'title-is_tfr' },
    { type: 'is_stupefiant', key: 'title-is_stupefiant' },
    { type: 'is_derive_sang', key: 'title-is_derive_sang' },
    { type: 'is_anticoagulant', key: 'title-is_anticoagulant' },
    { type: 'is_avk', key: 'title-is_avk' },
    { type: 'is_vaccin', key: 'title-is_vaccin' },
    { type: 'is_solute_dilution', key: 'title-is_solute_dilution' },
    {
        type: 'is_contraception_urgence',
        key: 'title-is_contraception_urgence',
    },
    {
        type: 'is_contraceptif_mineurs',
        key: 'title-is_contraceptif_mineurs',
    },
    { type: 'is_frigo', key: 'title-is_frigo' },
    { type: 'is_antibio_humain', key: 'title-is_antibio_humain' },
    {
        type: 'is_antibio_veterinaire',
        key: 'title-is_antibio_veterinaire',
    },
    {
        type: 'is_substance_psychotrope',
        key: 'title-is_substance_psychotrope',
    },
    { type: 'is_reactif_test', key: 'title-is_reactif_test' },
    { type: 'is_lait_premier_age', key: 'title-is_lait_premier_age' },
    { type: 'is_medicament_ivg', key: 'title-is_medicament_ivg' },
    { type: 'is_anticancereux', key: 'title-is_anticancereux' },
];

/**
 * Recherche et retourne les frais correspondant aux comptes acheteur et vendeur passés en paramètre dans le tableau de frais.
 *
 * @param fees - Le tableau des frais
 * @param buyerAccount - L'ID du compte acheteur
 * @param sellerAccount - L'ID du compte vendeur
 * @returns L'objet frais correspondant aux comptes ou null si non trouvé
 */
export const calculateFee = (fees, buyerAccount, sellerAccount): IFee => {
    const getFees = fees.find(
        (e) => e.buyer === buyerAccount && e.seller === sellerAccount
    );

    if (getFees) {
        return getFees;
    }

    return null;
};

/**
 * Arrondit un nombre à un nombre décimal donné
 *
 * @param {number} n - Le nombre à arrondir
 * @param {number} dp - Le nombre de décimales pour l'arrondi
 * @returns {number} Le nombre arrondi
 */
export const roundNumber = (n, dp): number => {
    const h = +'1'.padEnd(dp + 1, '0'); // 10 or 100 or 1000 or etc
    return Math.round(n * h) / h;
};

/**
 * Tableau définissant l'ordre des étapes obligatoires du processus de cloture de commande.
 * Chaque étape est associée à une description (Clé de traduction) et à la liste des types de clients (acheteur, vendeur) pour qui elle est obligatoire.
 */
export const mandatoryStepsOrder: IMandatoryStep[] = [
    {
        step: EnumStepClient.INVOICE,
        description: 'mandatory-step-invoice-desc',
        mandatory: [EnumTypeClient.BUYER, EnumTypeClient.SELLER],
    },
    {
        step: EnumStepClient.STEP1,
        description: 'mandatory-step-step1-desc',
        mandatory: [EnumTypeClient.BUYER],
    },
    {
        step: EnumStepClient.STEP2,
        description: 'mandatory-step-step2-desc',
        mandatory: [EnumTypeClient.BUYER, EnumTypeClient.SELLER],
    },
    {
        step: EnumStepClient.STEP3,
        description: 'mandatory-step-step3-desc',
        mandatory: [EnumTypeClient.SELLER],
    },
    {
        step: EnumStepClient.STEP4,
        description: 'mandatory-step-step4-desc',
        mandatory: [EnumTypeClient.BUYER],
    },
    {
        step: EnumStepClient.STEP5,
        description: 'mandatory-step-step5-desc',
        mandatory: [EnumTypeClient.BUYER, EnumTypeClient.SELLER],
    },
];

/**
 * Initialise le module de filtrage.
 *
 * @param {Object} _filterService - Le service de filtrage
 * @param {Object} externalService - Le service externe
 * @param {string} method - La méthode à appeler
 * @returns {Observable} Flux Observable pour initialiser le filtrage
 */
export const initFilterModule = (
    _filterService,
    externalService,
    method,
    name,
    urlFilters = null
) => {
    return _filterService
        .waitForFiltersInitialization(externalService, method, name)
        .pipe(
            catchError((error, caught) =>
                caught.pipe(
                    delay(5000),
                    take(30),
                    concatMap((_, i) =>
                        i === 2
                            ? throwError(
                                  () =>
                                      new Error(
                                          'Maximum retry attempts reached.'
                                      )
                              )
                            : of(error)
                    )
                )
            ),
            filter((initialized: boolean) => {
                return initialized;
            }),
            take(1),
            switchMap(() => {
                return _filterService.filters$.pipe(
                    take(1),
                    switchMap((filters) => {
                        const serviceFilters = AuthUtils.fullAesEncode(filters);
                        let formatUrlFilters = null;
                        if (urlFilters !== null) {
                            formatUrlFilters = decodeURIComponent(urlFilters);
                            formatUrlFilters = formatUrlFilters.replace(
                                /\s/g,
                                '+'
                            );
                        }

                        return _filterService.webservice(
                            {
                                filters: !urlFilters
                                    ? serviceFilters
                                    : formatUrlFilters,
                            },
                            true
                        );
                    })
                );
            })
        );
};

/**
 * Compare en profondeur deux objets pour déterminer s'ils sont égaux.
 *
 * @param obj1 Premier objet à comparer
 * @param obj2 Deuxième objet à comparer
 * @returns Booléen indiquant si les deux objets sont égaux en profondeur
 */
export const deepCompare = (obj1: any, obj2: any): boolean => {
    if (typeof obj1 !== typeof obj2) return false;

    if (typeof obj1 !== 'object' || obj1 === null || obj2 === null)
        return obj1 === obj2;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) return false;
        for (let i = 0; i < obj1.length; i++) {
            if (!deepCompare(obj1[i], obj2[i])) return false;
        }
        return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    if (!keys1.every((key) => keys2.includes(key))) return false;

    for (const key of keys1) {
        if (!deepCompare(obj1[key], obj2[key])) return false;
    }

    return true;
};

/**
 * StickyHeaderDirective handles making a header element sticky on scroll.
 *
 * Uses the renderer to toggle a 'sticky' class and set width when the element's
 * original top offset is passed on scroll. Handles removing sticky behavior when
 * scrolled back up.
 */
@Directive({
    selector: '[appStickyHeader]',
})
export class StickyHeaderDirective {
    private originalWidth: string;
    private originalTop: number;
    private isSticky: boolean = false;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.originalTop = this.elementRef.nativeElement.offsetTop;
            this.originalWidth =
                this.elementRef.nativeElement.offsetWidth + 'px';
        });
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        const componentTop = this.elementRef.nativeElement.offsetTop;
        const parentWidth =
            this.elementRef.nativeElement.parentElement.offsetWidth + 'px';

        if (windowScroll > this.originalTop && !this.isSticky) {
            this.renderer.addClass(this.elementRef.nativeElement, 'sticky');
            this.renderer.setStyle(
                this.elementRef.nativeElement,
                'width',
                parentWidth
            );
            this.isSticky = true;
        } else if (windowScroll <= this.originalTop && this.isSticky) {
            this.renderer.removeClass(this.elementRef.nativeElement, 'sticky');
            this.renderer.removeStyle(this.elementRef.nativeElement, 'width');
            this.isSticky = false;
        }
    }
}

/**
 * Creates a "chip" UI element that animates from the click location
 * to the shopping cart element, to provide visual feedback that an
 * item was added to the cart.
 *
 * @param event - The mouse click event that triggered adding to cart.
 * @param cartElementId - The ID of the shopping cart element.
 * @param renderer - The renderer to use for creating/manipulating DOM elements.
 * @returns Promise resolving to true if chip animation succeeded, false otherwise.
 */
export const createChip = (
    event: MouseEvent,
    cartElementId: string,
    renderer: Renderer2
): Promise<boolean> => {
    const cart = document.getElementById(cartElementId);
    if (!cart) {
        console.error("L'élément du panier n'a pas été trouvé.");
        return Promise.resolve(false);
    }

    const chipElement = renderer.createElement('div');
    renderer.addClass(chipElement, 'chip-class-basket');
    const startX = event.clientX;
    const startY = event.clientY;

    renderer.setStyle(chipElement, 'position', 'fixed');
    renderer.setStyle(chipElement, 'left', `${startX}px`);
    renderer.setStyle(chipElement, 'top', `${startY}px`);
    renderer.appendChild(chipElement, renderer.createText('+'));
    renderer.appendChild(document.body, chipElement);

    const cartRect = cart.getBoundingClientRect();
    const endX = cartRect.left + cartRect.width / 2 - startX;
    const endY = cartRect.top + cartRect.height / 2 - startY;

    chipElement.style.transition = 'transform 1s ease-in-out';
    chipElement.style.transform = `translate(${endX}px, ${endY}px)`;

    chipElement.addEventListener('transitionend', () => {
        chipElement.style.transition = 'all 0.1s ease-in-out';
        let times = 10;
        const vibrate = () => {
            if (times > 0) {
                renderer.setStyle(
                    chipElement,
                    'transform',
                    `translate(${endX + (times % 2 === 0 ? -5 : 5)}px, ${
                        endY + (times % 2 === 0 ? 5 : -5)
                    }px)`
                );

                renderer.setStyle(chipElement, 'opacity', times / 10);
                chipElement.style.opacity = times / 10;
                times--;
                setTimeout(vibrate, 30);
            } else {
                chipElement.style.transition = 'opacity 0.5s ease-in-out';
                chipElement.style.opacity = '0';
                setTimeout(() => {
                    renderer.removeChild(document.body, chipElement);
                }, 500);
            }
        };
        vibrate();
    });

    return Promise.resolve(true);
};

/**
 * Keeps the scroll position of the window the same after
 * the window is updated. Saves the current scroll position,
 * waits briefly, then resets the scroll position back to the saved value.
 */
export const keepSameScrollPosition = () => {
    const scrollPosition = window.scrollY.toString();
    setTimeout(() => {
        window.scrollTo(0, parseInt(scrollPosition, 10));
    }, 1);
};

/**
 * Validator function that checks if an array's length is greater than or equal to a minimum.
 * Returns an error object if length is less than min, null otherwise.
 */
export const minArrayLength = (min: number): ValidatorFn => {
    return (array: FormArray): { [key: string]: any } | null => {
        return array.length >= min
            ? null
            : {
                  minArrayLength: {
                      requiredLength: min,
                      actualLength: array.length,
                  },
              };
    };
};

/**
 * Validator function that checks if an array's length is less than or equal to a maximum.
 * Returns an error object if length is greater than max, null otherwise.
 */
export const maxArrayLength = (max: number): ValidatorFn => {
    return (array: FormArray): { [key: string]: any } | null => {
        return array.length <= max
            ? null
            : {
                  maxArrayLength: {
                      requiredLength: max,
                      actualLength: array.length,
                  },
              };
    };
};

/**
 * Calculates the remaining stock quantity by summing the quantities of
 * stock of type INITIAL, ENTRANCE, and RETURN, and subtracting the
 * quantities of stock of type EXIT.
 */
export const calculateRemainingStock = (stock: IPharmaOfferStock[]): number => {
    return stock.reduce((acc, curr) => {
        if (curr.type === EnumOfferStockType.ADJUSTMENT) {
            return curr.qty;
        } else if (
            [
                EnumOfferStockType.INITIAL,
                EnumOfferStockType.ENTRANCE,
                EnumOfferStockType.RETURN,
            ].includes(curr.type)
        ) {
            return acc + curr.qty;
        } else if ([EnumOfferStockType.EXIT].includes(curr.type)) {
            return acc - curr.qty;
        } else {
            return acc;
        }
    }, 0);
};

export const thresholdQtyValidator = (
    fullForm: UntypedFormGroup,
    totalQty: number,
    index: number
): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const currentQty = control.value;

        let noError = false;
        if (index > 0) {
            const thresholds: FormArray = fullForm.get(
                'thresholds'
            ) as FormArray;
            const previousGroup = thresholds.at(index - 1);
            const previousQty = previousGroup.get('minimum_qty').value;
            noError =
                Number(currentQty) > Number(previousQty) &&
                Number(currentQty) < Number(totalQty);
        } else {
            noError =
                Number(currentQty) > 0 && Number(currentQty) < Number(totalQty);
        }

        return noError ? null : { invalidThresholdQty: true };
    };
};

export const thresholdPriceValidator = (
    fullForm: UntypedFormGroup,
    initPrice: number,
    index: number
): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const price = control.value;
        let noError = false;
        if (index > 0) {
            const thresholds: FormArray = fullForm.get(
                'thresholds'
            ) as FormArray;
            const previousGroup = thresholds.at(index - 1);
            const previousPrice = previousGroup.get('unit_price').value;

            noError =
                Number(price) < Number(previousPrice) && Number(price) > 0;
        } else {
            noError = Number(price) < Number(initPrice) && Number(price) > 0;
        }
        return noError ? null : { invalidThresholdPrice: true };
    };
};

export const freeProductsQtyValidator = (
    fullForm: UntypedFormGroup,
    totalQty: number,
    index: number
): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const currentQty = control.value;

        let noError = false;
        if (index > 0) {
            const freeProducts: FormArray = fullForm.get(
                'freeProducts'
            ) as FormArray;
            const previousGroup = freeProducts.at(index - 1);
            const previousQty = previousGroup.get('minimum_qty').value;
            noError =
                Number(currentQty) > Number(previousQty) &&
                Number(currentQty) <= Number(totalQty);
        } else {
            noError =
                Number(currentQty) > 0 && Number(currentQty) < Number(totalQty);
        }

        return noError ? null : { invalidThresholdQty: true };
    };
};

@Pipe({
    name: 'customPercent',
})
export class CustomPercentPipe implements PipeTransform {
    transform(value: number): string {
        if (value < 1 && value >= 0) {
            return (value * 100).toFixed(2) + '%';
        } else {
            return value.toLocaleString('fr-FR', {
                style: 'percent',
                minimumFractionDigits: 2,
            });
        }
    }
}

/**
 * Calculates the basis stock by summing the quantities of all stocks with type 'INITIAL' or 'ENTRANCE'.
 *
 * @param stock - An array of IPharmaOfferStock objects.
 * @returns The total basis stock quantity.
 */
export const getBasisStock = (stock: IPharmaOfferStock[]): number => {
    return stock.reduce((acc, curr) => {
        if (curr.type === EnumOfferStockType.ADJUSTMENT) {
            return curr.qty;
        } else if (
            [EnumOfferStockType.INITIAL, EnumOfferStockType.ENTRANCE].includes(
                curr.type
            )
        ) {
            return acc + curr.qty;
        } else {
            return acc;
        }
    }, 0);
};

/**
 * Subscribes to a channel event and returns an unsubscribe function.
 *
 * @param channelName - The name of the channel to subscribe to.
 * @returns An observable that emits the channel object when a channel with the given name is found.
 */
export const findChannelByName = (channelName: string) => {
    return (source: Observable<any>) =>
        source.pipe(
            first((channels) =>
                channels.some((channel) => channel['name'] === channelName)
            ),
            map(
                (channels) =>
                    channels.find((channel) => channel['name'] === channelName)
                        .channel
            )
        );
};

/**
 * Subscribes to a channel event and returns an unsubscribe function.
 *
 * @param channelName - The name of the channel to subscribe to.
 * @param eventName - The name of the event to listen for.
 * @param callback - The callback function to be called when the event is triggered.
 * @param unsubscribeSignal - An optional Subject that, when emitted, will unsubscribe the listener.
 * @param onUnbind - An optional callback function to be called when the listener is unbound.
 * @returns A function that, when called, will unsubscribe the listener.
 */
export const subscribeToChannel = (
    channelName: string,
    eventName: string,
    callback: (data: any) => void,
    unsubscribeSignal?: Subject<void>,
    onUnbind?: () => void
) => {
    return (source: Observable<any>) => {
        let pipeline = source.pipe(
            findChannelByName(channelName),
            map((channel) => {
                channel.bind(eventName, callback);
                return () => {
                    channel.unbind(eventName);
                    if (onUnbind) onUnbind();
                };
            })
        );
        if (unsubscribeSignal) {
            pipeline = pipeline.pipe(takeUntil(unsubscribeSignal));
        }
        return pipeline;
    };
};
export const cartonSizes: ICartonSize[] = [
    { id: 'XS01', width: 15, height: 10, depth: 5, maxWeight: 1 },
    { id: 'XS02', width: 18, height: 13, depth: 6, maxWeight: 1.5 },
    { id: 'SM01', width: 20, height: 15, depth: 10, maxWeight: 2 },
    { id: 'SM02', width: 22, height: 16, depth: 11, maxWeight: 2.5 },
    { id: 'MD01', width: 25, height: 20, depth: 12, maxWeight: 3 },
    { id: 'MD02', width: 28, height: 22, depth: 14, maxWeight: 4 },
    { id: 'LG01', width: 30, height: 25, depth: 15, maxWeight: 5 },
    { id: 'LG02', width: 32, height: 27, depth: 16, maxWeight: 6 },
    { id: 'XL01', width: 35, height: 30, depth: 18, maxWeight: 7 },
    { id: 'XL02', width: 38, height: 32, depth: 20, maxWeight: 8 },
    { id: 'XL03', width: 40, height: 34, depth: 22, maxWeight: 9 },
    { id: 'XL04', width: 42, height: 36, depth: 24, maxWeight: 10 },
    { id: 'XL05', width: 45, height: 38, depth: 25, maxWeight: 12 },
    { id: 'XXL01', width: 48, height: 40, depth: 26, maxWeight: 15 },
    { id: 'XXL02', width: 50, height: 42, depth: 28, maxWeight: 17 },
    // { id: 'XXL03', width: 52, height: 44, depth: 30, maxWeight: 20 },
    // { id: 'XXL04', width: 55, height: 46, depth: 32, maxWeight: 22 },
    // { id: 'XXL05', width: 58, height: 48, depth: 34, maxWeight: 25 },
    // { id: 'XXXL01', width: 60, height: 50, depth: 35, maxWeight: 28 },
    // { id: 'XXXL02', width: 62, height: 52, depth: 36, maxWeight: 30 },
    // { id: 'XXXL03', width: 65, height: 54, depth: 38, maxWeight: 32 },
    // { id: 'XXXL04', width: 68, height: 56, depth: 40, maxWeight: 35 },
    // { id: 'XXXL05', width: 70, height: 58, depth: 42, maxWeight: 38 },
    // { id: 'S01', width: 72, height: 60, depth: 44, maxWeight: 40 },
    // { id: 'S02', width: 75, height: 62, depth: 45, maxWeight: 42 },
    // { id: 'S03', width: 78, height: 64, depth: 46, maxWeight: 45 },
    // { id: 'S04', width: 80, height: 66, depth: 48, maxWeight: 48 },
    // { id: 'S05', width: 82, height: 68, depth: 50, maxWeight: 50 },
    // { id: 'M01', width: 85, height: 70, depth: 52, maxWeight: 55 },
    // { id: 'M02', width: 88, height: 72, depth: 54, maxWeight: 60 },
    // { id: 'M03', width: 90, height: 74, depth: 56, maxWeight: 65 },
    // { id: 'M04', width: 92, height: 76, depth: 58, maxWeight: 70 },
    // { id: 'M05', width: 95, height: 78, depth: 60, maxWeight: 75 },
    // { id: 'L01', width: 98, height: 80, depth: 62, maxWeight: 80 },
    // { id: 'L02', width: 100, height: 82, depth: 64, maxWeight: 85 },
    // { id: 'L03', width: 103, height: 84, depth: 66, maxWeight: 90 },
    // { id: 'L04', width: 105, height: 86, depth: 68, maxWeight: 95 },
    // { id: 'L05', width: 108, height: 88, depth: 70, maxWeight: 100 },
    // { id: 'XL06', width: 110, height: 90, depth: 72, maxWeight: 105 },
    // { id: 'XL07', width: 113, height: 92, depth: 74, maxWeight: 110 },
    // { id: 'XL08', width: 115, height: 94, depth: 76, maxWeight: 115 },
    // { id: 'XL09', width: 118, height: 96, depth: 78, maxWeight: 120 },
    // { id: 'XL10', width: 120, height: 98, depth: 80, maxWeight: 125 },
];

/**
 * Distributes a list of products to a set of available cartons, taking into account the carton sizes and whether products can be mixed in the same carton.
 *
 * @param products - The list of carton products to be distributed.
 * @param availableCartons - The list of available carton sizes.
 * @param mixProductsInCartons - Indicates whether products can be mixed in the same carton.
 * @returns The list of cartons containing the distributed products.
 */
export const distributeProductsToCartons = (
    products: ICartonProduct[],
    availableCartons: ICartonSize[],
    mixProductsInCartons: boolean
): ICarton[] => {
    let cartons: ICarton[] = [];
    let remainingProducts = [...products];

    const sortedCartons = availableCartons.sort(
        (a, b) => b.width * b.height * b.depth - a.width * a.height * a.depth
    );

    while (remainingProducts.length > 0) {
        let carton: ICarton | null = null;
        remainingProducts.sort((a, b) => b.volume - a.volume);
        for (let i = 0; i < remainingProducts.length; i++) {
            const product = remainingProducts[i];
            const bestCartonSize = findBestCartonForProduct(
                product,
                sortedCartons,
                cartons,
                mixProductsInCartons
            );

            if (bestCartonSize) {
                carton =
                    cartons.find((c) => c.cartonSizeId === bestCartonSize.id) ||
                    createCarton(bestCartonSize);
                if (!cartons.includes(carton)) cartons.push(carton);
                placeProductInCarton(product, carton);
                remainingProducts.splice(i, 1);
                i--;
            } else {
                divideAndDistributeProduct(
                    product,
                    sortedCartons,
                    cartons,
                    mixProductsInCartons
                );
                remainingProducts.splice(i, 1);
                i--;
            }
        }
    }

    return cartons;
};

/**
 * Calculates the volume of a box given its width, height, and depth.
 *
 * @param box - An object with `width`, `height`, and `depth` properties representing the dimensions of the box.
 * @returns The volume of the box.
 */
export const calculateVolume = (box) => {
    return box.width * box.height * box.depth;
};

/**
 * Finds the best carton size for a given product, taking into account the available cartons and whether products can be mixed in the same carton.
 *
 * @param product - The carton product for which to find the best carton size.
 * @param availableCartons - The list of available carton sizes.
 * @param cartons - The list of existing cartons.
 * @param mixProductsInCartons - Indicates whether products can be mixed in the same carton.
 * @returns The best carton size for the product, or null if no suitable carton size is found.
 */
export const findBestCartonForProduct = (
    product: ICartonProduct,
    availableCartons: ICartonSize[],
    cartons: ICarton[],
    mixProductsInCartons: boolean
): ICartonSize | null => {
    const productVolume = product.volume;

    availableCartons.sort((a, b) => calculateVolume(a) - calculateVolume(b));

    for (const cartonSize of availableCartons) {
        const cartonVolume =
            cartonSize.width * cartonSize.height * cartonSize.depth;

        if (productVolume <= cartonVolume) {
            if (mixProductsInCartons) {
                const existingCarton = cartons.find((carton) => {
                    const remainingVolume = cartonVolume - carton.volumeUsed;
                    return (
                        carton.cartonSizeId === cartonSize.id &&
                        productVolume <= remainingVolume
                    );
                });
                if (existingCarton) {
                    return cartonSize;
                }
            }
            return cartonSize;
        }
    }
    return null;
};

/**
 * Places a carton product into a carton, updating the carton's volume used, weight, and insured value.
 *
 * @param product - The carton product to be placed in the carton.
 * @param carton - The carton to place the product in.
 * @returns Void.
 */
export const placeProductInCarton = (
    product: ICartonProduct,
    carton: ICarton
): void => {
    carton.products.push(product);
    carton.volumeUsed += product.volume;
    carton.weight += product.weight;
    carton.insuredValue += product.value;
};

/**
 * Divides and distributes a product across available cartons.
 *
 * @param product - The carton product to be distributed.
 * @param availableCartons - The list of available carton sizes.
 * @param cartons - The list of existing cartons.
 * @param mixProductsInCartons - Indicates whether products can be mixed in the same carton.
 * @returns Void.
 */
export const divideAndDistributeProduct = (
    product: ICartonProduct,
    availableCartons: ICartonSize[],
    cartons: ICarton[],
    mixProductsInCartons: boolean
): void => {
    const largestCartonSize = availableCartons[availableCartons.length - 1];
    const largestCartonVolume =
        largestCartonSize.width *
        largestCartonSize.height *
        largestCartonSize.depth;
    let remainingVolume = product.volume;
    let remainingWeight = product.weight;
    let remainingValue = product.value;
    // let remainingPrice = product.pricePackage;

    while (remainingVolume > 0) {
        let volumeToPlace: number;
        let weightToPlace: number;
        let valueToPlace: number;

        if (remainingVolume <= largestCartonVolume) {
            volumeToPlace = remainingVolume;
            weightToPlace = remainingWeight;
            valueToPlace = remainingValue;
        } else {
            volumeToPlace = largestCartonVolume;
            weightToPlace =
                (largestCartonVolume / product.volume) * product.weight;
            valueToPlace =
                (largestCartonVolume / product.volume) * product.value;
        }

        const productPart = {
            ...product,
            volume: volumeToPlace,
            weight: weightToPlace,
        };

        const bestCartonSize = findBestCartonForProduct(
            productPart,
            availableCartons,
            cartons,
            mixProductsInCartons
        );
        if (bestCartonSize) {
            const carton =
                cartons.find((c) => c.cartonSizeId === bestCartonSize.id) ||
                createCarton(bestCartonSize);
            if (!cartons.includes(carton)) cartons.push(carton);
            placeProductInCarton(productPart, carton);
        }

        remainingVolume -= volumeToPlace;
        remainingWeight -= weightToPlace;
        remainingValue -= valueToPlace;
    }
};

/**
 * Creates a new carton object with the specified carton size.
 *
 * @param cartonSize - The carton size to use for the new carton.
 * @returns The newly created carton object.
 */
export const createCarton = (cartonSize: ICartonSize): ICarton => {
    return {
        cartonSizeId: cartonSize.id,
        width: cartonSize.width,
        height: cartonSize.height,
        depth: cartonSize.depth,
        weight: 0,
        volumeUsed: 0,
        insuredValue: 0,
        products: [],
    };
};

/**
 * Generates a carton product object from a basket item.
 *
 * @param basket - The basket item to generate the carton product from.
 * @returns The generated carton product object.
 */
export const generateCartonProduct = (basket: IBasket): ICartonProduct => {
    const packing = basket.qty;
    return {
        id: basket.offer.product.id,
        label: basket.offer.product.label,
        package: packing,
        qty: basket.qty,
        volume:
            basket.offer.width *
            basket.offer.height *
            basket.offer.depth *
            packing,
        weight: (basket.offer.weight * packing) / 1000,
        value: basket.qty * basket.price,
    };
};

export const transportCompany = [
    {
        key: 'colis_prive',
        logo: 'colis_prive.jpg',
        label: 'Colis Privé',
    },
    {
        key: 'colissimo',
        logo: 'colissimo.jpg',
        label: 'Colissimo',
    },
    {
        key: 'chronopost',
        logo: 'chronopost.jpg',
        label: 'Chronopost',
    },
    {
        key: 'dhl',
        logo: 'dhl.jpg',
        label: 'DHL',
    },
    {
        key: 'fedex',
        logo: 'fedex.jpg',
        label: 'Fedex',
    },
    {
        key: 'chronomedical',
        logo: 'chronomedical.jpg',
        label: 'Chronopost HealtCare',
    },
    {
        key: 'owner',
        logo: 'owner.jpg',
        label: 'Transport du fournisseur',
    },
];

/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param num - The number to round.
 * @param decimal - The number of decimal places to round to. Defaults to 2.
 * @returns The rounded number.
 */
export const expepharmaRounded = (num: number, decimal: number = 2): number => {
    const factor = Math.pow(10, decimal);
    return Math.round(num * factor) / factor;
};

export const slideInOut = trigger('slideInOut', [
    transition(':enter', [
        style({ height: '0', overflow: 'hidden' }),
        animate('300ms ease-in', style({ height: '*', overflow: 'hidden' })),
    ]),
    transition(':leave', [
        style({ height: '*', overflow: 'hidden' }),
        animate('300ms ease-out', style({ height: '0', overflow: 'hidden' })),
    ]),
]);

/**
 * Pipe that formats currency values.
 *
 * Takes a number value and formats it as a currency based on the provided
 * currency code and display format. Caches the last formatted value
 * to improve performance of repeated formatting.
 */
@Pipe({
    name: 'expeCurrency',
    pure: false,
})
export class ExpeCurrencyPipe implements PipeTransform, OnDestroy {
    private subscription: Subscription;

    constructor(
        private translocoService: TranslocoService,
        private cdr: ChangeDetectorRef
    ) {
        this.subscription = this.translocoService.langChanges$.subscribe(() => {
            this.cdr.markForCheck();
        });
    }

    transform(
        value: number,
        currencyCode: string = 'EUR',
        display: 'symbol' | 'code' | 'name' = 'symbol'
    ): string {
        const locale = this.translocoService.getActiveLang();
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currencyCode,
            currencyDisplay: display,
        }).format(value);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

/**
 * Date pipe for formatting dates
 *
 * Formats dates based on the provided format and locale. Caches
 * formatted values to improve performance when the locale does not change.
 */
@Pipe({
    name: 'expeDate',
    pure: false,
})
export class ExpeDatePipe implements PipeTransform, OnDestroy {
    private subscription: Subscription;
    private lastValue: string | null = null;
    private lastParams: string | null = null;

    constructor(
        private translocoService: TranslocoService,
        private cdr: ChangeDetectorRef
    ) {
        this.subscription = this.translocoService.langChanges$.subscribe(() => {
            this.lastValue = null;
            this.cdr.markForCheck();
        });
    }

    transform(
        value: Date | string | number,
        format: string = 'shortDate'
    ): string | null {
        const locale = this.translocoService.getActiveLang();
        const params = `${locale}-${format}`;
        if (this.lastValue !== null && this.lastParams === params) {
            return this.lastValue;
        }
        this.lastParams = params;
        this.lastValue = new Intl.DateTimeFormat(
            locale,
            this.getOptions(format)
        ).format(new Date(value));
        return this.lastValue;
    }

    private getOptions(format: string): Intl.DateTimeFormatOptions {
        switch (format) {
            case 'shortDate':
                return { year: 'numeric', month: '2-digit', day: '2-digit' };
            case 'mediumDate':
                return { year: 'numeric', month: 'short', day: 'numeric' };
            case 'longDate':
                return {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                };
            case 'shortTime':
                return { hour: 'numeric', minute: 'numeric' };
            case 'mediumTime':
                return {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                };
            case 'longTime':
                return {
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZoneName: 'short',
                };
            case 'shortDateTime':
                return {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: 'numeric',
                    minute: 'numeric',
                };
            case 'mediumDateTime':
                return {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                };
            case 'longDateTime':
                return {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZoneName: 'short',
                };
            default:
                throw new Error(`Format inconnu : ${format}`);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

/**
 * Determines if a given account type is enabled for selection
 * @param type - The account type to check
 * @returns True if the account type is owner, wholesaler_pharma or wholesaler_nopharma, false otherwise
 */
export const enableForSelection = (type: TTypeAccount): boolean => {
    return (
        type === 'owner' ||
        type === 'wholesaler_pharma' ||
        type === 'wholesaler_nopharma'
    );
};

/**
 * Determines if VAT applies to an offer based on the country of the company
 * making the offer matching the country of the user's company.
 *
 * @param offer - The pharmacy offer
 * @param user - The logged in user
 * @returns True if the countries match, false otherwise
 */
export const isVatApply = (offer: IPharmaOfferFull, user: User): boolean => {
    return offer.user.legal.country_company === user.legal.country_company;
};

export const getPricePerGroup = (
    details: IBasket[],
    user: User,
    type?: 'EXCL' | 'INCL' | 'VAT'
): number => {
    return expepharmaRounded(
        details.reduce((acc, item) => {
            const vatRate = Number(
                isVatApply(item.offer, user) ? item?.offer?.vat || 0 : 0
            );
            switch (type) {
                case 'EXCL':
                    return acc + item.price * item.qty;
                case 'INCL':
                    return acc + item.price * item.qty * (1 + vatRate);
                case 'VAT':
                    return acc + item.price * item.qty * vatRate;
                default:
                    return acc + item.price * item.qty;
            }
        }, 0)
    );
};

/**
 * Calculates the full price including VAT for a given basket item.
 *
 * @param detail - The basket item to calculate the full price for.
 * @param user - The current user.
 * @returns The full price including VAT for the basket item.
 */
export const getFullInclPrice = (detail: IBasket, user: User): number => {
    const vatRate = Number(
        isVatApply(detail.offer, user) ? detail?.offer?.vat || 0 : 0
    );
    return detail.price * detail.qty * (1 + vatRate);
};

/**
 * Groups the given basket items by the user who owns the offer.
 *
 * @param type - The type of basket items to group.
 * @param items - The basket items to group.
 * @returns An array of basket groups, where each group contains the user who owns the offers and the details of the offers.
 */
export const groupUser = (
    type: EnumBasketType,
    items: IBasket[]
): IBasketGroupUser[] => {
    return items
        .filter((e) => e.type === type)
        .reduce((acc, item) => {
            let total = acc.length;
            let index = acc.findIndex((x) => x.user.id === item.offer.user.id);
            if (index === -1) {
                acc.push({
                    user: item.offer.user,
                    details: [],
                });
                index = total;
            }

            acc[index].details.push(item);
            return acc;
        }, [] as IBasketGroupUser[]);
};

/**
 * Calculates the line-item rates for a set of basket items, grouped by the user who owns the offers.
 *
 * @param items - An array of basket item groups, where each group contains the user who owns the offers and the details of the offers.
 * @param user - The current user.
 * @param totalFees - The total fees to be applied.
 * @returns An array of basket split VAT objects, where each object contains the VAT rate, the total amount, and the details of the line items.
 */
export const getLinePerRate = (
    items: IBasketGroupUser[],
    user: User,
    totalFees: number
): IBasketSplitVat[] => {
    let listRate = items
        .reduce((array, current) => {
            current.details.forEach((x) => {
                const vatRate = Number(
                    isVatApply(x.offer, user) ? x?.offer?.vat || 0 : 0
                );

                let total = array.length;
                let index = array.findIndex((item) => item.rate === vatRate);

                if (index === -1) {
                    array.push({
                        rate: vatRate,
                        amount: 0,
                        details: [],
                    });
                    index = total;
                }
                array[index].details.push({
                    price: x.price,
                    qty: x.qty,
                });
            });
            return array;
        }, [] as IBasketSplitVat[])
        .map((e) => {
            let amount =
                e.details.reduce((t, c) => t + c.price * c.qty, 0) * e.rate;
            return {
                ...e,
                amount: expepharmaRounded(amount),
            };
        });

    if (totalFees > 0) {
        const indexVatFees = listRate.findIndex((e) => e.rate === 0.2);

        if (indexVatFees !== -1) {
            listRate[indexVatFees] = {
                ...listRate[indexVatFees],
                amount: expepharmaRounded(
                    listRate[indexVatFees].amount + totalFees * 0.2
                ),
            };
        } else {
            listRate.push({
                rate: 0.2,
                amount: expepharmaRounded(totalFees * 0.2),
                details: [],
            });
        }
    }

    return listRate;
};

/**
 * Creates a validator function that checks if the value of a control is less than the value of another control in the same parent form group.
 *
 * @param toCompareKey - The key of the control to compare against.
 * @returns A validator function that returns an error object with the key 'lessThan' if the value is greater than or equal to the value of the compared control.
 */
export function lessThanValidator(toCompareKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.parent || !control.value) {
            return null;
        }

        const toCompareControl = control.parent.get(toCompareKey);
        if (!toCompareControl) {
            return null;
        }

        const toCompareValue = Number(toCompareControl.value);
        if (toCompareValue !== null && control.value >= toCompareValue) {
            return { lessThan: true };
        }
        return null;
    };
}

/**
 * Creates a validator function that checks if the value of a control is less than or equal to the value of another control in the same parent form group.
 *
 * @param toCompareKey - The key of the control to compare against.
 * @returns A validator function that returns an error object with the key 'lessThan' if the value is greater than the value of the compared control.
 */
export function lessOrEgalThanValidator(toCompareKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.parent || !control.value) {
            return null;
        }

        const toCompareControl = control.parent.get(toCompareKey);
        if (!toCompareControl) {
            return null;
        }

        const toCompareValue = Number(toCompareControl.value);
        if (toCompareValue !== null && control.value > toCompareValue) {
            return { lessThan: true };
        }
        return null;
    };
}

/**
 * Creates a validator function that checks if the value of a control is greater than the value of another control in the same parent form group.
 *
 * @param toCompareKey - The key of the control to compare against.
 * @returns A validator function that returns an error object with the key 'greaterThan' if the value is less than the value of the compared control.
 */
export function greaterThanValidator(toCompareKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.parent || !control.value) {
            return null;
        }

        const toCompareControl = control.parent.get(toCompareKey);
        if (!toCompareControl) {
            return null;
        }

        if (
            control?.value &&
            (control.value === null || control.value === '')
        ) {
            return null;
        }

        const toCompareValue = Number(toCompareControl.value);
        if (
            toCompareValue !== null &&
            !isNaN(control.value) &&
            control.value < toCompareValue
        ) {
            return { greaterThan: true };
        }
        return null;
    };
}

/**
 * Checks if the provided value is a non-null, non-empty string.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a non-null, non-empty string, `false` otherwise.
 */

export const isExpeString = (value: string): boolean => {
    return typeof value === 'string' && value !== null && value.trim() !== ''
        ? true
        : false;
};

/**
 * Checks if the provided value is a non-null, non-empty number.
 *
 * @param value - The value to check.
 * @returns `true` if the value is a non-null, non-empty number, `false` otherwise.
 */
export const isExpeNumber = (value: number): boolean => {
    return typeof value === 'number' && value !== null && !isNaN(value)
        ? true
        : false;
};

export function enumToArray(enumObj: any): string[] {
    return Object.keys(enumObj).map((key) => enumObj[key]);
}
