import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule,
    isDevMode,
    LOCALE_ID,
} from '@angular/core';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ExtraOptions,
    PreloadAllModules,
    RouterModule,
    Router,
} from '@angular/router';
import { ExpepharmaModule } from '@expepharma';
import { ExpepharmaConfigModule } from '@expepharma/services/config';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ExpepharmaApiModule } from '@expepharma/lib/api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { apiServices } from 'app/api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { HistoryService } from 'app/core/history/history.service';
import { AlertService } from 'app/core/alerts/alert.service';
import { ExpepharmaAlertModule } from '@expepharma/components/alert/alert.module';
import * as Sentry from '@sentry/angular-ivy';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import fr from '@angular/common/locales/fr';
import en from '@angular/common/locales/en';
import ar from '@angular/common/locales/ar';
import de from '@angular/common/locales/de';
import it from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { FilterService } from './core/services/filters/filters.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from './shared/shared.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { ValidationAccountModule } from './modules/admin/common/components/account/validation-account/validation-account.module';
import { PhoneConfirmationModule } from './modules/admin/common/components/account/phone-confirmation/phone-confirmation.module';
import { ChoiceTypeValidationModule } from './modules/admin/common/components/account/choice-type-validation/choice-type-validation.module';
import { VersionService } from './core/services/version/version.service';
// import { environment } from 'environments/environment';

registerLocaleData(en);
registerLocaleData(fr);
registerLocaleData(ar);
registerLocaleData(de);
registerLocaleData(it);

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    paramsInheritanceStrategy: 'always',
    onSameUrlNavigation: 'reload',
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        ExpepharmaModule,
        ExpepharmaConfigModule.forRoot(appConfig),
        ExpepharmaApiModule.forRoot(apiServices),
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        SharedModule,
        CoreModule,
        MatSnackBarModule,
        LayoutModule,
        HttpClientModule,
        ExpepharmaAlertModule,
        MatButtonModule,
        MatMenuModule,
        TranslocoMessageFormatModule.forRoot(),
        TranslocoModule,
        NgxWebstorageModule.forRoot(),
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //     enabled: !isDevMode(),
        //     registrationStrategy: 'registerWhenStable:30000',
        // }),
        ValidationAccountModule,
        PhoneConfirmationModule,
        ChoiceTypeValidationModule,
        // NgbModule,
    ],
    providers: [
        HistoryService,
        FilterService,
        AlertService,
        VersionService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: TRANSLOCO_SCOPE, useValue: ['landing'] },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: (filterService: FilterService) => () =>
        //         filterService.initFilters(),
        //     deps: [FilterService],
        //     multi: true,
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
